import type { Maybe } from '@xing-com/crate-common-graphql-types';
import type {
  JobMatchingHighlightsV2Fragment,
  JobTeaserVisibleJobFragment,
} from '@xing-com/crate-jobs-graphql';

import type { XingletInterface } from '../../jobs-xinglet';
import { JobsXingletWrapper } from '../../jobs-xinglet';

import { JobTeaserListItemSkeleton } from './job-teaser-list-item-skeleton';

/**
 * Should only be defined when a job includes matching highlights. If defined
 * and both reasons or error are undefined, it will render the loading state.
 */
type MatchingReasons = {
  /**
   * The markdown of the MR that will be rendered as the highlight if there's
   * no error
   */
  reasons?: string;
  /**
   * The error message if the MR are not loading and there's an error
   */
  error?: string | null;
  /**
   * The index of the matching reason, used for the tracking flags
   */
  index: number;
};

export type JobTeaserListItemProps = {
  job: JobTeaserVisibleJobFragment;
  isNew?: boolean;
  url: string;
  routerState?: unknown;
  highlight?: string | null;
  matchingReasons?: MatchingReasons | undefined;
  matchingHighlights?: Maybe<JobMatchingHighlightsV2Fragment>;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onContextMenu?: () => void;
  onAuxClick?: () => void;
  actions?: React.ReactElement;
  className?: string;
  'data-testid'?: string;
  headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  showEmploymentType?: boolean;
  showRatingMarker?: boolean;
  showRefreshedDate?: boolean;
  'aria-label'?: string;
};

export type JobTeaserListItemXinglet = XingletInterface<JobTeaserListItemProps>;

export const JobTeaserListItem = JobsXingletWrapper<JobTeaserListItemProps>(
  '@xing-com/crate-jobs-components-job-teaser-list-item',
  <JobTeaserListItemSkeleton />
);
